// Run this example by adding <%= javascript_pack_tag 'hello_typescript' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

console.log('Hello world from typescript');

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import HomeApp from '../src/home/App';

ReactDOM.render(
  <HomeApp />,
  document.getElementById('main')
);
