import * as React from 'react';

import {makeStyles} from '@material-ui/core/styles';

import {TwitterTweetEmbed} from 'react-twitter-embed';

// @ts-ignore
import LogoDark from '../../../../assets/images/logo-dark.svg'

export interface Props {
}

const useStyles = makeStyles((theme) => ({}));

export const Footer: React.FC<Props> = (props) => {
  const classes = useStyles();

  return <div className="">
    <div className="c-box-1">
      <div className="c-box-inner">
        <h2 className="h2 text-center">最新の記事</h2>
        <ul className="list-group-flush list-group-flush-no-left-right-space">
          <li className="list-group-item"><a href="/blog/1">炎上の書き込みをする人は0.5%</a></li>
          <li className="list-group-item"><a href="/blog/2">ネットの書き込みは言いたい人だけが書いている</a></li>
          <li className="list-group-item"><a href="/blog/3">わずか0.015%の否定的な声が大きく見えている</a></li>
          <li className="list-group-item"><a href="/blog/4">なんと炎上参加者は年収が高く役職のある人</a></li>
          <li className="list-group-item"><a href="/blog/5">ゆがんだ正義感が炎上の原動力</a></li>
        </ul>
      </div>
    </div>

    <div className="c-box-1">
      <div className="c-box-inner">
        <h2 className="h2 text-center">注目のツイート</h2>
        <ul className="list-group-flush list-group-flush-no-left-right-space">
          <li className="list-group-item"><a href="https://negatch.com/home/TwitterJP/1344659586075283456/snapshot/20210122092124">Twitter Japan / 明けましておめでとうございます！みなさん、2021年もどうぞよろしくお願いします🎍✨</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/Sankei_news/1352542767419858944/snapshot/20210122092822">産経ニュース / 無観客で２兆４０００億円損失　東京五輪・パラで試算</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/mainichi/1352541516590407680/snapshot/20210122093618">毎日新聞 / 「あなたのために、あなたのご子孫に借金を増やしていくということなんでしょうか」</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/nhk_news/1352520129611927558/snapshot/20210122093907">NHKニュース / ボクシング井岡一翔 先月の防衛戦での“入れ墨” 厳重注意 JBC</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/jcp_cc/1352206220887638017/snapshot/20210122093948">日本共産党 / 政権を変えましょう。 核兵器禁止条約、菅首相「署名する考えない」</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/MLIT_JAPAN/1352848672636104705/snapshot/20210124025921">国土交通省 / 【#大雪 に対する緊急発表】 23日夕方～24日にかけ関東甲信地方は平野部も含め大雪の見込み。不要不急の外出は控え、ドライバーの皆様は冬タイヤの装着...</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/JMA_bousai/1352847977476317184/snapshot/20210124025955">気象庁防災情報 / 【緊急発表！】大雪に対する国土交通省緊急発表を行いました。23日夕方から24日にかけて、関東甲信地方...</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/Kantei_Saigai/1352783106814652417/snapshot/20210124030024">首相官邸(災害・危機管理情報) / 【#新型コロナウイルス】 緊急事態宣言に関する菅総理からのメッセージをご覧ください。皆様のご協力をお願いいたします。</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/Kantei_Saigai/1352567189367267329/snapshot/20210124030104">首相官邸(災害・危機管理情報) / 感染対策の決め手となるワクチンについて、3億1,400万回分の供給を受ける契約の締結に至りました。</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/BFJNews/1352569143883558912/snapshot/20210124031601">BuzzFeed Japan News / 新型コロナ対策でワクチンへの期待がかけられる中、不安を煽るような報道が出ています。...</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/tv_asahi_news/1351495896609955841/snapshot/20210120045524">テレ朝news / 自民若手議員 二階氏らに「73歳定年制」厳守を要望</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/fnn_news/1351367726363598848/snapshot/20210120045645">FNNプライムオンライン / FNNプライムオンライン</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/bunshun_online/1351439953440382978/snapshot/20210120045934">文春オンライン / 女性を指名したいのにと思うけど…なぜ女性美容師は圧倒的に少ないのか</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/bunshun_online/1351455027055927298/snapshot/20210120051102">文春オンライン / 「滝沢くんともっとうまくやっとけばよかった」元MADE・稲葉光（30）がジャニーズ事務所退所へ</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/kanboukansen/1351528577993347074/snapshot/20210120052121">新型コロナウイルス感染症対策推進室 / 休業やシフト減など厳しい状況におかれているパート・アルバイトの方々で、中小企業の雇い主から休業手当をもらえない方は、自身で労働局に申請し一人最大1万1千円/日の #休業支援金 を受給できます。</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/MHLWitter/1349295769384321024/snapshot/20210120053223">厚生労働省 / 本日新たに7府県へ緊急事態宣言発出が決定されました。</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/gov_online/1346109129627152384/snapshot/20210120054615">内閣府政府広報オンライン / 地域で輝く女性、高齢者も障がい者も誰もが居場所と役割を持つコミュニティ、地元の課題解決にがんばる若者など、キーパーソンのお話を順次公開！</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/asahi_apital/1351723681416835075/snapshot/20210120070747">医療サイト「朝日新聞アピタル」 / ドイツのメルケル首相は19日、各州首相との会議で、新型コロナウイルス対策の強化で合意しました。</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/MHLWitter/1343466895807139840/snapshot/20210120071106">厚生労働省 / 新型コロナウイルス感染症の患者等の発生状況（各自治体公表資料集計分、空港検疫）や、現在の状況および厚生労働省の対応に関する最新情報などについては、厚生労働省ウェブサイトの「新着情報一覧」に掲載しています。</a></li>
          <li className="list-group-item"><a href="https://negatch.com/home/MHLWitter/1347112795628716038/snapshot/20210120071028">厚生労働省 / 本日、1都3県への緊急事態宣言発令が決定されました。</a></li>
        </ul>
      </div>
    </div>

    <footer className="site-footer">
      <div className="container">
        <div className="site-footer-branding">
          <div className="r-mb-md site-footer-branding-logo">
            <a href="/" rel="home">
              <img src={LogoDark} />
            </a>
          </div>

          {/* <!--<div className="site-footer-branding-logo-text">
          <a href="<?php echo esc_url(home_url('/')); ?>" rel="home"><?</a>
        </div>--> */}
        </div>

        <div className="">
          <ul className="footer-nav">
            <li className="footer-nav__item"><a href="/privacy">プライバシーポリシー</a></li>
          </ul>
        </div>

        <div className="footer-copy">
          (c) negatch
        </div>
      </div>
    </footer>
  </div>
}
