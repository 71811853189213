import * as React from 'react';
import { useState, useEffect } from 'react';

import Router from 'react-router/Router';

import { makeStyles } from '@material-ui/core/styles';

import Api from '../../common/Api'

import { TweetView } from "./TweetView"

export interface ITweet {
  id: string;
  text: string;
}

export interface IReply {
  id: string;
  text: string;
}

export interface Props {
  tweets?: Array<{ url: string, tweet: ITweet, replies: Array<IReply> }>;
}

const useStyles = makeStyles((theme) => ({
  moodArea: {
    textAlign: 'center',
  },
}));

export const TweetList: React.FC<Props> = (props) => {
  const [data, setData] = useState([])
  const classes = useStyles();

  return <React.Fragment>
    <div>
      {props.tweets ? props.tweets.map((tweet, index) =>
        <div key={`tweet-${tweet['tweet_model'].id}`}>
          <ul className="list-group-flush list-group-flush-no-left-right-space">
            {tweet['replies'].map((reply, replyIndex) =>
              <li className="list-group-item" key={`reply-${reply.id}`}>
                {reply.text.replace(/^@\S* /, '')}
              </li>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  </React.Fragment>
}
