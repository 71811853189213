import * as React from 'react';
import { useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

interface ITweet {
  id: number;
  text: string;
}

interface IReply {
  id: number;
  text: string;
}

interface Props {
  onClick: any;
  tweets?: Array<{ tweet: ITweet, replies: Array<IReply> }>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  colorDefault: {
    backgroundColor: '#000000',
  },
  title: {
    flexGrow: 1,
    color: '#fe0000',
    fontWeight: 'bold',
  },
  urlInputField: {
    textAlign: 'center',
  },
  submitButton: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  muiButtonContained: {
    backgroundColor: '#ff6000',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  infoText: {
    color: '#fe0000',
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  debugArea: {

  },
}));

export const SearchForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [url, setUrl] = useState("")
  const [token, setToken] = useState("")
  const [actionPath, setActionPath] = useState("/home")

  const onChange = (setter) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value)
  }

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const params = {
      url: url
    }
    props.onClick(params)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const csrfMetaElement: HTMLMetaElement = document.getElementsByName('csrf-token')[0] as HTMLMetaElement;
    setToken(csrfMetaElement.content)

    const regex = /https:\/\/twitter.com\/([^/]*)\/status\/([0-9]+).*$/
    const matchUrl = url.match(regex) as RegExpMatchArray

    if (matchUrl !== null) {
      setActionPath(`/home/detail/${matchUrl[1]}/${matchUrl[2]}`)
    } else {
      setActionPath(`/error`)
    }
  }

  return <React.Fragment>
    <form className="search-form r-mb-lg" action={actionPath} onSubmit={onSubmit} method="get">
      <input
        name="url"
        type="search"
        className="search-field"
        value={url}
        placeholder="ツイートのURLで否定率を検索"
        onChange={onChange(setUrl)}
      />
      <input type="submit" value="Check" className="search-submit" />
    </form>
  </React.Fragment>
}
