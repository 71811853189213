import * as React from 'react';
import { useState, useEffect } from 'react';

import Router from 'react-router/Router';

import { makeStyles } from '@material-ui/core/styles';

import Api from '../../common/Api'

import {ITweet} from '../../interfaces/ITweet'

// export interface IReply {
//   id: string;
//   text: string;
// }

export interface Props {
  tweets?: Array<{ url: string }>;
}

const useStyles = makeStyles((theme) => ({
  moodArea: {
    textAlign: 'center',
  },
}));

export const RecentDetailList: React.FC<Props> = (props) => {
  const [tweets, setTweets] = useState<Array<ITweet>>([])
  const classes = useStyles();

  useEffect(
    () => {
      const params = {};

      Api()
        .get(`/api/tweets/recent`, {
          params,
        })
        .then(response => {
          console.info(response.data['tweets'])
          setTweets(response.data['tweets']);
          return response;
        })
        .catch(error => {
          console.error(error);
          console.error('error');
        });
    },
    []
  )

  return <React.Fragment>
    <div className="c-box-1">
      <div className="c-box-inner">
        {console.info(tweets)
        }
        <h2 className="h2 text-center">最近チェックされたツイート</h2>
        <ul className="list-group-flush list-group-flush-no-left-right-space">
          {tweets.map((tweet, count) => {
            return <li key={`recentTweet${count}`} className="list-group-item">
              <a href={tweet['tweet']['url']}>
                {tweet['tweet']['profile_image_url'] ? <img src={tweet['tweet']['profile_image_url']} /> : null}
                {tweet['tweet']['text']}
              </a>
            </li>
          }
          )}
        </ul>
      </div>
    </div>
  </React.Fragment>

}
