import axios from 'axios'

require('es6-promise').polyfill()

export default function Api() {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest'
  }

  return (axios.create({
    headers: headers,
    transformResponse: [(data) => {
      try {
        return JSON.parse(data);
      } catch (Exception) {
        return data;
      }
    }]
  }))
}
