import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { createBrowserHistory } from 'history'

import { makeStyles } from '@material-ui/core/styles';

import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';

import * as ReactGA from 'react-ga';
import { SearchForm } from "../components/home/SearchForm"
import { TweetList } from "../components/home/TweetList"
import { RenderPieChart } from "../components/home/RenderPieChart"
import { Footer } from "../components/home/Footer"


import CharacterMain from '../../../assets/images/character_main.png'
import HowToCopyUrlOfTweet1 from '../../../assets/images/how-to-copy-url-of-tweet-1.png'
import HowToCopyUrlOfTweet2 from '../../../assets/images/how-to-copy-url-of-tweet-2.png'

import { RecentDetailList } from '../components/home/RecentDetailList'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  colorDefault: {
    backgroundColor: '#000000',
  },
  title: {
    flexGrow: 1,
    color: '#fe0000',
    fontWeight: 'bold',
  },
  urlInputField: {
    textAlign: 'center',
  },
  submitButton: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  muiButtonContained: {
    backgroundColor: '#ff6000',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  infoText: {
    color: '#fe0000',
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  moodArea: {
    textAlign: 'center',
  },
  debugArea: {},
}));

export default function MainApp(props) {
  const classes = useStyles();
  const [data, setData] = useState([])
  const [sentiment, setSentiment] = useState({})

  const [responseHasError, setResponseHasError] = useState(false)

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, []);

  // Create a history of your choosing (we're using a browser history in this case)
  const history = createBrowserHistory({});
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  const onChangeInputs = useCallback((inData) => {
    setResponseHasError(false)

    const searchParams = new URLSearchParams(inData);

    fetchTweetData(searchParams)
  }, []);

  async function fetchTweetData(params) {
    const response = await fetch(`/api/tweets?${params.toString()}`)
    const responseJson = await response.json()
    setResponseHasError(await !response.ok)
    setData(responseJson['tweets']);
    setSentiment(responseJson['sentiment']);
  }

  const renderSearchForm = () =>
    <SearchForm onClick={onChangeInputs} />

  const renderTweetList = () =>
    <div className={classes.debugArea}>
      {
        data.length > 0 ? <TweetList tweets={data} /> : null
      }
    </div>

  const renderNoData = () =>
    <div>NotFound</div>

  return (
    <div className="main-inner">
      {renderSearchForm()}

      {responseHasError ? renderNoData() : renderTweetList()}

      <div>

        <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="exampleModalLabel">ツイートURLのコピー方法</h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="text-center">
                  <img className="w-70ps" src={HowToCopyUrlOfTweet1} />
                </p>
                <p className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-arrow-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                  </svg>
                </p>
                <p className="text-center">
                  <img className="w-70ps" src={HowToCopyUrlOfTweet2} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="c-box-1">
        <div className="c-box-inner">
          <div className="text-center">
            <h2 className="c-heading-1 color-primary r-mb-lg">リプライの否定率をチェックできるネガっち</h2>
            <img className="c-character-1" src={CharacterMain} />
          </div>
          <p>「ネガっち」はツイートの返信（リプライ）の中に<strong>否定的なコメントがどれくらいあるかを判定してくれるサービス</strong>です。</p>

          <p>常々、SNS上で誹謗中傷に苦しんで心を病んでしまったり自殺してしまった人がいることに心を痛めていました。</p>
          <p>ふとした発言で炎上してしまい心無い言葉に苦しんでいる人に対して<strong>「そこまで深刻に悩まなくていいんだよ」</strong>というメッセージを込めて「negatch」（ネガっち）を作りました。</p>
          <p>negatchはツイートのリプライを否定率として可視化しており、その処理には<strong>IBMのAI「WATSON」</strong>を利用しています。</p>
          <p>negatchを使って不安を吹き飛ばしましょう！！</p>
          <hr />
          <h3 className="h5 mt-sm-4 text-center">使い方</h3>
          <div className="d-flex justify-content-center">
            <ol>
              <li>気になったツイートのURLをコピー</li>
              <li>negatchを開いてURLをペースト！</li>
              <li>否定率が判明！！</li>
            </ol>
          </div>
          <p className="text-center">キャラが適切なアドバイスをくれるよ♪</p>

          <p className="text-center">
            <button className="btn btn-dark" data-toggle="modal" data-target="#exampleModal">ツイートのURLのコピー方法を見る</button>
          </p>


        </div>
      </div>

      <RecentDetailList />

      <Footer />
    </div>
  );
}
